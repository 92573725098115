import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    bankAccountList: getCommonInitialState(),
    bankAccountDelete: getCommonInitialState(),
    bankAccountView: getCommonInitialState(),
    bankAccountSave: getCommonInitialState(),
    bankAccountStatusUpdate: getCommonInitialState(),
    withdrawalList: getCommonInitialState(),
    withdrawalView: getCommonInitialState(),
    withdrawalStatusUpdate: getCommonInitialState(),
    userWallet: getCommonInitialState(),
    requestPayOut: getCommonInitialState(),
    settlementCancel: getCommonInitialState(),
};

const WithdrawSlice = createSlice({
    name: "withdraw",
    initialState,
    reducers: {

        bankAccountListStart: (state) => { state.bankAccountList = getCommonStart() },
        bankAccountListSuccess: (state, action) => { state.bankAccountList = getCommonSuccess(action.payload) },
        bankAccountListFailure: (state, action) => { state.bankAccountList = getCommonFailure(action.payload) },

        bankAccountSaveStart: (state) => { state.bankAccountSave = getCommonStart() },
        bankAccountSaveSuccess: (state, action) => { state.bankAccountSave = getCommonSuccess(action.payload) },
        bankAccountSaveFailure: (state, action) => { state.bankAccountSave = getCommonFailure(action.payload) },

        bankAccountViewStart: (state) => { state.bankAccountView = getCommonStart() },
        bankAccountViewSuccess: (state, action) => { state.bankAccountView = getCommonSuccess(action.payload) },
        bankAccountViewFailure: (state, action) => { state.bankAccountView = getCommonFailure(action.payload) },

        bankAccountDeleteStart: (state, action) => { state.bankAccountDelete = getCommonStart(action.payload) },
        bankAccountDeleteSuccess: (state, action) => { state.bankAccountDelete = getCommonSuccess(action.payload) },
        bankAccountDeleteFailure: (state, action) => { state.bankAccountDelete = getCommonFailure(action.payload) },

        bankAccountStatusUpdateStart: (state, action) => { state.bankAccountStatusUpdate = getCommonStart(action.payload) },
        bankAccountStatusUpdateSuccess: (state, action) => { state.bankAccountStatusUpdate = getCommonSuccess(action.payload) },
        bankAccountStatusUpdateFailure: (state, action) => { state.bankAccountStatusUpdate = getCommonFailure(action.payload) },

        withdrawalListStart: (state) => { state.withdrawalList = getCommonStart() },
        withdrawalListSuccess: (state, action) => { state.withdrawalList = getCommonSuccess(action.payload) },
        withdrawalListFailure: (state, action) => { state.withdrawalList = getCommonFailure(action.payload) },

        withdrawalViewStart: (state) => { state.withdrawalView = getCommonStart() },
        withdrawalViewSuccess: (state, action) => { state.withdrawalView = getCommonSuccess(action.payload) },
        withdrawalViewFailure: (state, action) => { state.withdrawalView = getCommonFailure(action.payload) },

        withdrawalStatusUpdateStart: (state) => { state.withdrawalStatusUpdate = getCommonStart() },
        withdrawalStatusUpdateSuccess: (state, action) => { state.withdrawalStatusUpdate = getCommonSuccess(action.payload) },
        withdrawalStatusUpdateFailure: (state, action) => { state.withdrawalStatusUpdate = getCommonFailure(action.payload) },

        // Wallet 
        userWalletStart: (state) => { state.userWallet = getCommonStart() },
        userWalletSuccess: (state, action) => { state.userWallet = getCommonSuccess(action.payload) },
        userWalletFailure: (state, action) => { state.userWallet = getCommonFailure(action.payload) },

        requestPayOutStart: (state) => { state.requestPayOut = getCommonStart() },
        requestPayOutSuccess: (state, action) => { state.requestPayOut = getCommonSuccess(action.payload) },
        requestPayOutFailure: (state, action) => { state.requestPayOut = getCommonFailure(action.payload) },

        // Settlement Cancel
        settlementCancelStart: (state, action) => { state.settlementCancel = getCommonStart(action.payload) },
        settlementCancelSuccess: (state, action) => { state.settlementCancel = getCommonSuccess(action.payload) },
        settlementCancelFailure: (state, action) => { state.settlementCancel = getCommonFailure(action.payload) },

    }
});

export const {
  bankAccountDeleteFailure,
  bankAccountDeleteStart,
  bankAccountDeleteSuccess,
  bankAccountListFailure,
  bankAccountListStart,
  bankAccountListSuccess,
  bankAccountSaveFailure,
  bankAccountSaveStart,
  bankAccountSaveSuccess,
  bankAccountViewFailure,
  bankAccountViewStart,
  bankAccountViewSuccess,
  bankAccountStatusUpdateFailure,
  bankAccountStatusUpdateStart,
  bankAccountStatusUpdateSuccess,
  withdrawalListFailure,
  withdrawalListStart,
  withdrawalListSuccess,
  withdrawalViewFailure,
  withdrawalViewStart,
  withdrawalViewSuccess,
  withdrawalStatusUpdateFailure,
  withdrawalStatusUpdateStart,
  withdrawalStatusUpdateSuccess,
  userWalletFailure,
  userWalletStart,
  userWalletSuccess,
  requestPayOutFailure,
  requestPayOutStart,
  requestPayOutSuccess,
  settlementCancelFailure,
  settlementCancelStart,
  settlementCancelSuccess,
} = WithdrawSlice.actions;

export default WithdrawSlice.reducer;