import React from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import { useTranslation } from "react-multi-lang";

const PaymentLinkCreatedModal = (props) => {

  const t = useTranslation("payment.payment_link_created")

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.paymentLinkCreated}
        onHide={props.closePaymentLinkCreatedModal}
        backdrop="static"
      >
        <Modal.Body>
          <Link
            to="/invoice-payment"
            className="modal-close"
          >
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Link>
          <div className="payment-link-created-box invoice-payment-created">
            <div className="payment-link-created-header-sec">
              <div className="payment-link-created-header-icon-sec">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="none"
                  viewBox="0 0 31 32"
                >
                  <g
                    fill="#111"
                    stroke="#111"
                    strokeWidth="0.5"
                    clipPath="url(#clip0_21_5094)"
                  >
                    <path d="M13.668 21.488l-4.702-.005c-2.83-.042-5.11-2.45-5.092-5.376.017-2.87 2.242-5.185 5.017-5.222l4.704.003c.563 0 1.016-.472 1.012-1.054-.005-.582-.464-1.054-1.027-1.055l-4.704-.002c-3.956-.003-7.14 3.311-7.11 7.403.03 4.09 3.26 7.41 7.217 7.413l4.702.005c.563-.01 1.008-.489.994-1.07-.014-.569-.46-1.027-1.009-1.037l-.002-.003h0zM30.428 16.174a7.544 7.544 0 00-2.132-5.242c-1.35-1.394-3.178-2.177-5.078-2.175L18.51 8.75c-.563 0-1.016.47-1.012 1.053.004.582.463 1.055 1.026 1.056l4.704.007a5.047 5.047 0 013.634 1.555c2.015 2.072 2.038 5.428.05 7.495a5 5 0 01-3.611 1.547l-4.703-.005c-.563 0-1.016.47-1.012 1.053.004.583.464 1.055 1.027 1.056l4.7.007c3.955 0 7.137-3.31 7.114-7.399z"></path>
                    <path d="M20.415 15.123l-8.649-.009c-.563-.01-1.024.453-1.03 1.035-.005.582.446 1.062 1.01 1.073h.035l8.649.01c.562-.01 1.007-.49.993-1.072-.013-.568-.459-1.026-1.008-1.037z"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_21_5094">
                      <path
                        fill="#fff"
                        d="M0 0H31V31H0z"
                        transform="translate(0 .5)"
                      ></path>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <h3>{props.paymentId ? t("updated") : t("created")}</h3>
            </div>
            <div className="payment-link-created-body-sec">
              <div className="payment-link-created-card">
                <div className="payment-link-created-left-sec">
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="none"
                    viewBox="0 0 18 18"
                  >
                    <path
                      fill="#111"
                      stroke="#111"
                      strokeWidth="0.5"
                      d="M10.045 12.668l-2.246 2.115c-1.37 1.254-3.524 1.114-4.813-.312-1.264-1.399-1.229-3.522.08-4.79L5.31 7.566a.687.687 0 00.016-.966.675.675 0 00-.958-.048L2.124 8.667c-1.89 1.78-1.939 4.819-.111 6.788 1.827 1.969 4.84 2.122 6.73.343l2.245-2.115a.688.688 0 000-.966.674.674 0 00-.941-.048h-.002 0zM15.686 2.548a4.882 4.882 0 00-3.341-1.58 4.556 4.556 0 00-3.387 1.233l-2.25 2.115a.687.687 0 00-.016.966c.26.28.69.302.958.05l2.248-2.115a3.257 3.257 0 012.423-.883c1.88.097 3.378 1.713 3.346 3.609a3.33 3.33 0 01-1.037 2.375l-2.246 2.115a.687.687 0 00-.016.966c.26.28.689.303.958.05l2.245-2.114c1.887-1.78 1.938-4.816.115-6.787z"
                    ></path>
                    <path
                      fill="#111"
                      stroke="#111"
                      strokeWidth="0.5"
                      d="M10.442 6.547l-4.13 3.89a.686.686 0 00-.033.964.676.676 0 00.974.05l4.13-3.89a.688.688 0 000-.966.675.675 0 00-.94-.048z"
                    ></path>
                  </svg> */}
                  <span>
                    {props.paymentLinkCreated.api_url}
                  </span>
                </div>
                <div className="payment-link-created-right-sec">
                <CopyToClipboard
                  text={props.paymentLinkCreated.api_url}
                  onCopy={() => getSuccessNotificationMessage("URL copied to clipboard!")}>
                     <Button className="copy-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        fill="#5C9EEB"
                        height="20"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                      </svg>
                      <span>{t("copy_button_text")}</span>
                    </Button>
                </CopyToClipboard>
                </div>
              </div>
              {/* <div className="payment-link-created-item-sec">
                <div className="payment-link-created-top-sec">
                  <h5>Parameter</h5>
                  <h5 className="align-item-text-right">Values</h5>
                </div>
                <div className="payment-link-details-item-card">
                    <div className="payment-link-details-table-info">
                      <h4>amount</h4>
                      <div className="small-notes-info">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="none"
                          viewBox="0 0 13 13"
                        >
                          <path
                            fill="#979BA2"
                            fillRule="evenodd"
                            d="M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.15 3.575a.65.65 0 101.3 0v-4.55a.65.65 0 10-1.3 0v4.55zm.65-6.5a.65.65 0 110-1.3.65.65 0 010 1.3z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>
                          This parameter specifies the amount the user is initiating payment for.
                        </span>
                      </div>
                    </div>
                    <h4 className="align-item-text-right">10.08</h4>
                  </div>
                  <div className="payment-link-details-item-card">
                    <div className="payment-link-details-table-info">
                      <h4>order_id</h4>
                      <div className="small-notes-info">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="none"
                          viewBox="0 0 13 13"
                        >
                          <path
                            fill="#979BA2"
                            fillRule="evenodd"
                            d="M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.15 3.575a.65.65 0 101.3 0v-4.55a.65.65 0 10-1.3 0v4.55zm.65-6.5a.65.65 0 110-1.3.65.65 0 010 1.3z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>
                          This unique identifier is used to track the payment status. It aids in updating the payment status on your platform.
                        </span>
                      </div>
                    </div>
                    <h4 className="align-item-text-right">
                    12345                    
                    </h4>
                  </div>
              </div> */}
              <div className="payment-link-created-btn-sec">
                <Link to="/invoice-payment" className="efi-payment-btn">{t("done_button_text")}</Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentLinkCreatedModal;
