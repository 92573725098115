import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import configuration from "react-global-configuration";
import "./PaymentLayout.css";
import PayOutOne from "./PayOut/PayOutOne";
import PayOutTwo from "./PayOut/PayOutTwo";
import PaymentSuccess from "./PaymentSuccess";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { transactionViewStart } from "../../store/slices/PaymentSlice";
import PaymentError from "./PaymentError";
import Skeleton from "react-loading-skeleton";

const PayoutLayout = (props) => {
  const [step, setStep] = useState(1);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const transactionView = useSelector((state) => state.payment.transactionView);

  const [skipRender, setSkipRender] = useState(true);
  const [email, setEmail] = useState()

  useEffect(() => {}, []);

  useEffect(() => {
    setStep(1);
    if (searchParams.get("transaction_unique_id")) {
      dispatch(
        transactionViewStart({
          transaction_unique_id: searchParams.get("transaction_unique_id"),
        })
      );
    }
  }, []);

  return (
    <>
     <div className="payment-flow-page-sec">
        <div className="payment-flow-header-sec">
          <Container>
            <Row>
              <Col md={12}>
                <div className="efi-payment-left-wrapped">
                  <a>
                    <img
                      className="efi-login"
                      src={configuration.get("configData.site_logo")}
                      alt="logo"
                    />
                  </a>
                  {/* <h1>
                    Easy, Fast & Secure <br /> <span>Payment</span>
                  </h1> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="payment-main-wrapped">
          <Container>
            <Row className="align-items-start justify-content-md-center">
              {/* <Col md={7}>
                <div className="efi-payment-left-wrapped">
                  <a href="https://efimarkets.com">
                    <img
                      className="efi-login"
                      src={configuration.get("configData.site_logo")}
                      alt="logo"
                    />
                  </a>
                  <h1>
                    Easy, Fast & Secure <br /> <span>Payment</span>
                  </h1>
                </div>
              </Col> */}
              <Col md={6}>
                {searchParams.get("transaction_unique_id") ? (
                  transactionView.loading ? (
                    <Skeleton height={500} />
                  ) : Object.keys(transactionView.data).length > 0 ? (
                    transactionView.data.transaction.status == 0 ? (
                      <div className="efi-payment-right-wrapped">
                        {step == 1 && <PayOutOne setStep={setStep} transaction={transactionView.data.transaction} setEmail={setEmail} />}
                        {step == 2 && <PayOutTwo setStep={setStep} transaction={transactionView.data.transaction} email={email} />}
                        {step == 3 && <PaymentSuccess setStep={setStep} />}
                      </div>
                    ) : [1, 2].includes(
                        transactionView.data.transaction.status
                      ) ? (
                      <div className="efi-payment-right-wrapped">
                        <PaymentSuccess />
                      </div>
                    ) : (
                      <div className="efi-payment-right-wrapped">
                        <PaymentError error="payment-failed" />
                      </div>
                    )
                  ) : (
                    <div className="efi-payment-right-wrapped">
                      <PaymentError
                        error={
                          transactionView.error.error_code == 148
                            ? "session-expired"
                            : "invalid-transaction"
                        }
                        errorMessage={transactionView.error.error}
                      />
                    </div>
                  )
                ) : (
                  <div className="efi-payment-right-wrapped">
                    <PaymentError error="invalid-transaction" />
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default PayoutLayout;
