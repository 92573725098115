import AdminSlice from "./AdminSlice";
import CategorySlice from "./CategorySlice";
import CommonSlice from "./CommonSlice";
import PaymentSlice from "./PaymentSlice";
import TraderSlice from "./TraderSlice";
import WithdrawSlice from "./WithdrawSlice";
import PayoutSlice from "./PayoutSlice";

export const reducers = {

    common: CommonSlice,
    admin: AdminSlice,
    category: CategorySlice,
    payment: PaymentSlice,
    withdraw: WithdrawSlice,
    trader: TraderSlice,
    payout: PayoutSlice,

};