import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ButtonLoader } from "../Helper/Loader";
import { customizePaymentResetStart } from "../../store/slices/PaymentSlice";
import { useTranslation } from "react-multi-lang";

const ResetModal = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("reset_modal");
  const customizePaymentReset = useSelector((state) => state.payment.customizePaymentReset);

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.resetModal}
        onHide={props.closeResetModal}
      >
        <Modal.Body>
          <Button onClick={props.closeResetModal} className="modal-close">
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Button>
          <div className="add-bank-account-modal-sec">
            <div className="settlement-top-sec">
              <h3>{t("heading")}</h3>
            </div>
            <Row className="align-items-center">
              <Col md={12}>
                <p>
                  {t("are_you_sure_to_reset_para")}
                </p>
                <div className="request-payout-payment-method-btn-sec">
                  <Button
                    className="efi-cancel-btn"
                    onClick={props.closeResetModal}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    className="efi-auth-btn"
                    disabled={customizePaymentReset.buttonDisable}
                    type="submit"
                    onClick={() => dispatch(customizePaymentResetStart())}
                  >
                    {customizePaymentReset.buttonDisable ? (
                      <ButtonLoader />
                    ) : (
                      t("reset_btn.text")
                    )
                    }
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResetModal;
