import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  bankAccountDeleteFailure,
  bankAccountDeleteSuccess,
  bankAccountListFailure,
  bankAccountListSuccess,
  bankAccountSaveFailure,
  bankAccountSaveSuccess,
  bankAccountStatusUpdateFailure,
  bankAccountStatusUpdateSuccess,
  bankAccountViewFailure,
  bankAccountViewSuccess,
  requestPayOutFailure,
  requestPayOutSuccess,
  settlementCancelFailure,
  settlementCancelSuccess,
  userWalletFailure,
  userWalletSuccess,
  withdrawalListFailure,
  withdrawalListSuccess,
  withdrawalStatusUpdateFailure,
  withdrawalStatusUpdateSuccess,
  withdrawalViewFailure,
  withdrawalViewSuccess,
} from "../slices/WithdrawSlice";


function* bankAccountListAPI(action) {
  yield getCommonSaga({
    apiUrl: "bank_accounts",
    payload: action.payload,
    successNotify: false,
    success: bankAccountListSuccess,
    failure: bankAccountListFailure,
  });
}

function* bankAccountDeleteAPI(action) {
  const bankAccountListData = yield select(state => state.withdraw.bankAccountList.data);
  yield getCommonSaga({
    apiUrl: "bank_accounts/destroy",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(bankAccountDeleteSuccess({ data: data }));
      if (Object.keys(bankAccountListData).length > 0)
        store.dispatch(bankAccountListSuccess({
          bank_accounts: [
            ...bankAccountListData.bank_accounts.filter(cat => cat.id !== action.payload.bank_account_id)
          ],
          total_bank_accounts: data.total_bank_accounts,
        }))
    },
    failure: bankAccountDeleteFailure
  })
}

function* bankAccountViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "bank_accounts/show",
    payload: action.payload,
    successNotify: false,
    success: bankAccountViewSuccess,
    failure: bankAccountViewFailure,
  });
}

function* bankAccountSaveAPI(action) {
  yield getCommonSaga({
    apiUrl: "bank_accounts/store",
    payload: action.payload,
    successNotify: true,
    success: bankAccountSaveSuccess,
    failure: bankAccountSaveFailure,
  });
}

function* bankAccountStatusUpdateAPI(action) {
  const bankAccountListData = yield select(state => state.withdraw.bankAccountList.data);
  yield getCommonSaga({
    apiUrl: "bank_accounts/default",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(bankAccountStatusUpdateSuccess({ data: data }));
      if (Object.keys(bankAccountListData).length > 0)
        store.dispatch(bankAccountListSuccess({
            ...bankAccountListData,
            bank_accounts: bankAccountListData.bank_accounts.map(account => {
            if (account.id === action.payload.bank_account_id) {
              return { ...account, is_default: 1 };
            }
            return { ...account, is_default: 0 };
          })
        }))
    },
    failure: bankAccountStatusUpdateFailure,
  });
}

function* withdrawalListAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_withdrawals",
    payload: action.payload,
    successNotify: false,
    success: withdrawalListSuccess,
    failure: withdrawalListFailure,
  });
}

function* withdrawalViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_withdrawals/show",
    payload: action.payload,
    successNotify: false,
    success: withdrawalViewSuccess,
    failure: withdrawalViewFailure,
  });
}

function* withdrawalStatusUpdateAPI(action) {
  yield getCommonSaga({
    apiUrl: "withdrawal/status",
    payload: action.payload,
    successNotify: true,
    success: withdrawalStatusUpdateSuccess,
    failure: withdrawalStatusUpdateFailure,
  });
}

function* userWalletAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_wallet",
    payload: action.payload,
    successNotify: false,
    success: userWalletSuccess,
    failure: userWalletFailure,
  });
}

function* requestPayOutAPI(action) {
  const withdrawalListData = yield select(state => state.withdraw.withdrawalList.data);
  const userWalletData = yield select(state => state.withdraw.userWallet.data);
  yield getCommonSaga({
    apiUrl: "user_withdrawals/store",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(requestPayOutSuccess({ data: data }));
      if (Object.keys(withdrawalListData).length > 0)
        store.dispatch(withdrawalListSuccess({
          ...withdrawalListData,
          user_withdrawals: [data.user_withdrawal,...withdrawalListData.user_withdrawals],
          total_user_withdrawals: withdrawalListData.total_user_withdrawals + 1
        }))
      if (Object.keys(userWalletData).length > 0)
       {
        store.dispatch(userWalletSuccess({
          ...userWalletData,
          user_withdrawals: {
            ...userWalletData.user_withdrawals,
            initiated : userWalletData.user_withdrawals.initiated + 1,
            total : userWalletData.user_withdrawals.total + 1,
          },
          user_wallet : data.user_wallet,
        }))
       }
    },
    failure: requestPayOutFailure,
  });
}

function* settlementCancelAPI(action) {
  const withdrawalListData = yield select(state => state.withdraw.withdrawalList.data);
  const userWalletData = yield select(state => state.withdraw.userWallet.data);
  yield getCommonSaga({
    apiUrl: "user_withdrawals/cancel",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(settlementCancelSuccess({ data: data }));
      if (Object.keys(withdrawalListData).length > 0)
        store.dispatch(withdrawalListSuccess({
          ...withdrawalListData,
          user_withdrawals: withdrawalListData.user_withdrawals.map((item)=> item.id  !== action.payload.user_withdrawal_id ? item : data.user_withdrawal),
          total_user_withdrawals: withdrawalListData.total_user_withdrawals - 1
        }))
      if (Object.keys(userWalletData).length > 0)
        {
          store.dispatch(userWalletSuccess({
            ...userWalletData,
            user_withdrawals: {
              ...userWalletData.user_withdrawals,
              initiated : userWalletData.user_withdrawals.initiated - 1,
              cancelled : userWalletData.user_withdrawals.cancelled + 1,
            },
            user_wallet : data.user_wallet,
          }))
        }
    },
    failure: settlementCancelFailure,
  });
}

export default function* WithdrawSaga() {
  yield all([
    yield takeLatest('withdraw/bankAccountListStart', bankAccountListAPI),
    yield takeLatest('withdraw/bankAccountDeleteStart', bankAccountDeleteAPI),
    yield takeLatest('withdraw/bankAccountViewStart', bankAccountViewAPI),
    yield takeLatest('withdraw/bankAccountSaveStart', bankAccountSaveAPI),
    yield takeLatest('withdraw/bankAccountStatusUpdateStart', bankAccountStatusUpdateAPI),
    yield takeLatest('withdraw/withdrawalListStart', withdrawalListAPI),
    yield takeLatest('withdraw/withdrawalViewStart', withdrawalViewAPI),
    yield takeLatest('withdraw/withdrawalStatusUpdateStart', withdrawalStatusUpdateAPI),
    yield takeLatest('withdraw/userWalletStart', userWalletAPI),
    yield takeLatest('withdraw/requestPayOutStart', requestPayOutAPI),
    yield takeLatest('withdraw/settlementCancelStart', settlementCancelAPI),
  ]);
}

