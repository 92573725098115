import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import configuration from "react-global-configuration";
import "./PaymentLayout.css";
import PaymentSuccess from "./PaymentSuccess";
import PaymentError from "./PaymentError";
import { useDispatch, useSelector } from "react-redux";
import {
  transactionViewStart,
  transactionUpdateStart,
} from "../../store/slices/PaymentSlice";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";

const StyloPayResponse = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const transactionUpdate = useSelector(
    (state) => state.payment.transactionUpdate
  );
  const transactionView = useSelector((state) => state.payment.transactionView);

  const navigate = useNavigate(); 

  useEffect(() => {
    console.log("params", params);
    if(!["success", "failure"].includes(params.type)){
      navigate("/404")
    } 
    else
      dispatch(
        transactionViewStart({
          transaction_unique_id: localStorage.getItem("transaction_unique_id"),
        })
    );
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("transaction_unique_id") &&
      !transactionView.loading &&
      Object.keys(transactionView.data).length > 0 &&
      transactionView.data.transaction.status == 0
    ) {
      dispatch(
        transactionUpdateStart({
          step: 2,
          transaction_unique_id: localStorage.getItem("transaction_unique_id"),
          payment_id: (Math.random() + 1).toString(36).substring(7),
          status: params.type == 'success' ? 2 : 5,
        })
      );
      localStorage.removeItem("transaction_unique_id");
    }
  }, [transactionView]);

  return (
    <>
    <div className="payment-flow-page-sec">
        <div className="payment-flow-header-sec">
          <Container>
            <Row>
              <Col md={12}>
                <div className="efi-payment-left-wrapped">
                  <a>
                    <img
                      className="efi-login"
                      src={configuration.get("configData.site_logo")}
                      alt="logo"
                    />
                  </a>
                  {/* <h1>
                    Easy, Fast & Secure <br /> <span>Payment</span>
                  </h1> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="payment-main-wrapped">
          <Container>
            <Row className="align-items-start justify-content-center">
              {/* <Col md={7}>
                <div className="efi-payment-left-wrapped">
                  <a href="https://efimarkets.com">
                    <img
                      className="efi-login"
                      src={configuration.get("configData.site_logo")}
                      alt="logo"
                    />
                  </a>

                  <h1>
                    Easy, Fast & Secure <br /> <span>Payment</span>
                  </h1>
                </div>
              </Col> */}
              <Col md={6}>
                {transactionView.loading ? (
                  <Skeleton height={500} />
                ) : Object.keys(transactionView.data).length > 0 ? params.type == 'success' ? (
                  transactionUpdate.loading ? (
                    <Skeleton height={500} />
                  ) : Object.keys(transactionUpdate.data).length > 0 ? (
                    <div className="efi-payment-right-wrapped">
                      <PaymentSuccess />
                    </div>
                  ) : (
                    <div className="efi-payment-right-wrapped">
                      <PaymentError
                        error={
                          transactionUpdate.error.error_code == 148
                            ? "session-expired"
                            : "invalid-transaction"
                        }
                        errorMessage={transactionUpdate.error.error}
                      />
                    </div>
                  )
                ) : (
                  <div className="efi-payment-right-wrapped">
                    <PaymentError
                      error="payment-failed"
                    />
                  </div>
                ) : (
                  <div className="efi-payment-right-wrapped">
                    <PaymentError
                      error={
                        transactionView.error.error_code == 148
                          ? "session-expired"
                          : "invalid-transaction"
                      }
                      errorMessage={transactionView.error.error}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default StyloPayResponse;
