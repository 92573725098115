import React, { useState } from "react";
import { Form, Button, InputGroup, Image, Modal } from "react-bootstrap";
import "./PaymentLayout.css";
import { useSelector } from "react-redux";
import PayInOne from "./PayInOne";
import PayInTwo from "./PayInTwo";
import PayInPayment from "./PayInPayment";
import PaymentSuccess from "./PaymentSuccess";
import PayInCard from "./PayInCard";

const PayIn = (props) => {
  const transactionView = useSelector((state) => state.payment.transactionView);

  const [step, setStep] = useState(1);
  const [paymentData, setPaymentData] = useState();
  return (
    <>
      {step == 1 ? (
        <PayInOne setStep={setStep} setPaymentData={setPaymentData} />
      ) : step == 2 ? transactionView.data.transaction.payment_type == 'card' ? (
        <PayInCard setStep={setStep} setPaymentData={setPaymentData} />
      ) : (
        <PayInPayment setStep={setStep} paymentData={paymentData} />
      ) : (
        <PaymentSuccess />
      )}
    </>
  );
};

export default PayIn;
