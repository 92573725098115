import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Dropdown,
  Image,
  Tab,
  Nav,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import PaymentLinkCreatedModal from "./PaymentLinkCreatedModal";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  paymentSaveStart,
  paymentViewStart,
} from "../../store/slices/PaymentSlice";
import { ButtonLoader } from "../Helper/Loader";
import PageLoader from "../Helper/PageLoader";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";

const CreatePaymentLink = (props) => {

  const t = useTranslation("payment")
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  const paymentView = useSelector((state) => state.payment.paymentView);
  const paymentSave = useSelector((state) => state.payment.paymentSave);
  const [paymentLinkCreated, setPaymentLinkCreated] = useState(false);
  const [paymentType, setPaymentType] = useState(1);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("create_payment.name.required"))
      .test(
        "len",
        t("create_payment.name.length"),
        (val) => !val || val.toString().length < 255
      ),
    payment_type: Yup.string().required(t("create_payment.payment_type.required")),
    callback_url: Yup.string()
      .required(t("create_payment.callback_url.required"))
      .matches(
        /^((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zAZ0-9-%]+&?)?$/,
        t("create_payment.callback_url.invalid_url")
      ),
    success_url: Yup.string()
      .required(t("create_payment.success_url.required"))
      .matches(
        /^((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zAZ0-9-%]+&?)?$/,
        t("create_payment.success_url.invalid_url")
      ),
    failure_url: Yup.string()
      .required(t("create_payment.failure_url.required"))
      .matches(
        /^((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zAZ0-9-%]+&?)?$/,
        t("create_payment.failure_url.invalid_url")
      ),
    cancel_url: Yup.string()
      .required(t("create_payment.cancel_url.required"))
      .matches(
        /^((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zAZ0-9-%]+&?)?$/,
        t("create_payment.cancel_url.invalid_url")
      ),
    merchant_commission_percentage: Yup.number()
      .required(t("create_payment.merchant_commission_percentage.required"))
      .max(100, t("create_payment.merchant_commission_percentage.max"))
      .min(0, t("create_payment.merchant_commission_percentage.min"))
  });
  

  const closePaymentLinkCreatedModal = () => {
    setPaymentLinkCreated(false);
  };

  const handleSubmit = (values) => {
    dispatch(paymentSaveStart({ ...values, type: paymentType, payment_link_id: params.paymentId || "" }));
  };

  useEffect(() => {
    if (!skipRender && Object.keys(paymentSave.data).length > 0)
      setPaymentLinkCreated(paymentSave.data.payment_link);
    setSkipRender(false);
  }, [paymentSave]);

  useEffect(() => {
    if (
      !profile.loading &&
      Object.keys(profile.data).length > 0 &&
      params.paymentId
    )
      dispatch(paymentViewStart({ payment_link_id: params.paymentId }));
  }, [params.paymentId]);

  useEffect(()=> {
    if(!skipRender && Object.keys(paymentView.data).length > 0 && Object.keys(paymentView.data.payment_link).length > 0)
    {
      setPaymentType(paymentView.data.payment_link.type);
    }
  }, [paymentView])

  return (
    <>
      <div className="payment-link-sec">
        <div className="payment-link-box">
          {params.paymentId && paymentView.loading ? (
            <>
              <Skeleton height={60} width={250} className="mb-3"/>
              <Skeleton height={400} />
            </>
          ) : (Object.keys(paymentView.data).length > 0 || !params.paymentId) && (Object.keys(profile.data).length > 0) ? (
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <div className="create-payment-link-header-sec">
                <div className="create-payment-link-tab-nav-sec">
                  <div className="payment-link-details-header-sec">
                    <Button
                      className="back-btn"
                      onClick={() => navigate("/api-payment")}
                    >
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="12"
                        fill="none"
                        viewBox="0 0 8 12"
                      >
                        <path
                          fill="#090830"
                          fillRule="evenodd"
                          d="M6.063 11.276L.67 6.373a.494.494 0 010-.746L6.063.724a.903.903 0 011.19 0 .718.718 0 010 1.082L2.64 6l4.614 4.194c.328.299.328.783 0 1.082a.903.903 0 01-1.19 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#979BA2"
                          d="M13.293 5.707L9.828 9.171a4 4 0 000 5.657l3.465 3.465c.63.63 1.707.184 1.707-.707V6.414c0-.89-1.077-1.337-1.707-.707z"
                        ></path>
                      </svg>
                    </Button>
                    <h3>{params.paymentId ? t("create_payment.edit_title") : t("create_payment.create_title")}</h3>
                  </div>
                  {/* <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="first"
                        onClick={() => setPaymentType(1)}
                        active={paymentType == 1}
                        // disabled={params.paymentId}
                      >
                        PayIn
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="second"
                        onClick={() => setPaymentType(2)}
                        active={paymentType == 2}
                        // disabled={params.paymentId}
                      >
                        Pay Out
                      </Nav.Link>
                    </Nav.Item>
                  </Nav> */}
                </div>
              </div>
              <Formik
                initialValues={
                  params.paymentId
                    ? {
                        name: paymentView.data.payment_link.name || "",
                        description:
                          paymentView.data.payment_link.description || "",
                        payment_type:
                          paymentView.data.payment_link.payment_type || "",
                        callback_url:
                          paymentView.data.payment_link.callback_url || "",
                        success_url:
                          paymentView.data.payment_link.success_url || "",
                        failure_url:
                          paymentView.data.payment_link.failure_url || "",
                        cancel_url:
                          paymentView.data.payment_link.cancel_url || "",
                        merchant_commission_percentage: paymentView.data.payment_link.merchant_commission || "",
                      }
                    : {
                        name: "",
                        description: "",
                        payment_type: "",
                        callback_url: "",
                        success_url: "",
                        failure_url: "",
                        cancel_url: "",
                        merchant_commission_percentage: "",
                      }
                }
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, setFieldTouched, errors }) => (
                  <FORM className="create-payment-link-form">
                    <div className="create-payment-link-sec">
                      <div className="create-payment-link-from-sec">
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>{t("create_payment.payment_type.label")} *</Form.Label>
                              <div className="payment-type-box">
                                {profile.data.upi_method ? (
                                  <label className="payment-type-item" htmlFor="payment-type-1">
                                    <input
                                      name="payment_type"
                                      className="radio"
                                      id="payment-type-1"
                                      type="radio"
                                      checked={values.payment_type === "upi"}
                                      onChange={() => setFieldValue("payment_type", "upi")}
                                      disabled={params.paymentId}
                                    />
                                    <div className="payment-type-card">
                                      <h4>{t("create_payment.upi")}</h4>
                                      <Image
                                        className="payment-type-icon"
                                        src={window.location.origin + "/img/payment-type/upi-icon.svg"}
                                        type="image/png"
                                      />
                                    </div>
                                  </label>
                                ) : null}
                                {profile.data.card_method ? (
                                  <label className="payment-type-item" htmlFor="payment-type-2">
                                    <input
                                      name="payment_type"
                                      className="radio"
                                      id="payment-type-2"
                                      type="radio"
                                      checked={values.payment_type === "card"}
                                      onChange={() => setFieldValue("payment_type", "card")}
                                      disabled={params.paymentId}
                                    />
                                    <div className="payment-type-card">
                                      <h4>{t("create_payment.card")}</h4>
                                      <Image
                                        className="payment-type-icon"
                                        src={window.location.origin + "/img/payment-type/card-icon.svg"}
                                        type="image/png"
                                      />
                                    </div>
                                  </label>
                                ) : null}
                                {profile.data.crypto_method ? (
                                  <label className="payment-type-item" htmlFor="payment-type-3">
                                    <input
                                      name="payment_type"
                                      className="radio"
                                      id="payment-type-3"
                                      type="radio"
                                      checked={values.payment_type === "crypto"}
                                      onChange={() => setFieldValue("payment_type", "crypto")}
                                      disabled={params.paymentId}
                                    />
                                    <div className="payment-type-card">
                                      <h4>{t("create_payment.crypto")}</h4>
                                      <Image
                                        className="payment-type-icon"
                                        src={window.location.origin + "/img/payment-type/crypto-icon.svg"}
                                        type="image/png"
                                      />
                                    </div>
                                  </label>
                                ) : null}
                              </div>
                              {!profile.data.upi_method && !profile.data.card_method && !profile.data.crypto_method ? (
                                <label>
                                  <div className="small-notes-info">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      fill="none"
                                      viewBox="0 0 13 13"
                                    >
                                      <path
                                        fill="#FF0000"
                                        fillRule="evenodd"
                                        d="M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.15 3.575a.65.65 0 101.3 0v-4.55a.65.65 0 10-1.3 0v4.55zm.65-6.5a.65.65 0 110-1.3.65.65 0 010 1.3z"
                                        clipRule="evenodd"
                                      ></path>
                                    </svg>
                                    <span style={{ color: "red" }}>
                                      {t("create_payment.payment_method_not_found")}
                                    </span>
                                  </div>
                                </label>
                              ) : null}
                              <ErrorMessage component={"div"} name="payment_type" className="errorMsg w-100" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>{t("create_payment.callback_url.label")} *</Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t("create_payment.callback_url.placeholder")}
                                name="callback_url"
                              />
                              <ErrorMessage component={"div"} name="callback_url" className="errorMsg w-100" />
                              <div className="small-notes-info">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  fill="none"
                                  viewBox="0 0 13 13"
                                >
                                  <path
                                    fill="#979BA2"
                                    fillRule="evenodd"
                                    d="M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.15 3.575a.65.65 0 101.3 0v-4.55a.65.65 0 10-1.3 0v4.55zm.65-6.5a.65.65 0 110-1.3.65.65 0 010 1.3z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                                <span>{t("create_payment.callback_url_info")}</span>
                              </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>{t("create_payment.cancel_url.label")} *</Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t("create_payment.cancel_url.placeholder")}
                                name="cancel_url"
                              />
                              <ErrorMessage component={"div"} name="cancel_url" className="errorMsg w-100" />
                              <div className="small-notes-info">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  fill="none"
                                  viewBox="0 0 13 13"
                                >
                                  <path
                                    fill="#979BA2"
                                    fillRule="evenodd"
                                    d="M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.15 3.575a.65.65 0 101.3 0v-4.55a.65.65 0 10-1.3 0v4.55zm.65-6.5a.65.65 0 110-1.3.65.65 0 010 1.3z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                                <span>{t("create_payment.cancel_url_info")}</span>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>{t("create_payment.name.label")} *</Form.Label>
                              <Field className="form-control" placeholder={t("create_payment.name.placeholder")} name="name" />
                              <ErrorMessage component={"div"} name="name" className="errorMsg w-100" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>{t("create_payment.success_url.label")} *</Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t("create_payment.success_url.placeholder")}
                                name="success_url"
                              />
                              <ErrorMessage component={"div"} name="success_url" className="errorMsg w-100" />
                              <div className="small-notes-info">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  fill="none"
                                  viewBox="0 0 13 13"
                                >
                                  <path
                                    fill="#979BA2"
                                    fillRule="evenodd"
                                    d="M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.15 3.575a.65.65 0 101.3 0v-4.55a.65.65 0 10-1.3 0v4.55zm.65-6.5a.65.65 0 110-1.3.65.65 0 010 1.3z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                                <span>{t("create_payment.success_url_info")}</span>
                              </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>{t("create_payment.failure_url.label")} *</Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t("create_payment.failure_url.placeholder")}
                                name="failure_url"
                              />
                              <ErrorMessage component={"div"} name="failure_url" className="errorMsg w-100" />
                              <div className="small-notes-info">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  fill="none"
                                  viewBox="0 0 13 13"
                                >
                                  <path
                                    fill="#979BA2"
                                    fillRule="evenodd"
                                    d="M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.15 3.575a.65.65 0 101.3 0v-4.55a.65.65 0 10-1.3 0v4.55zm.65-6.5a.65.65 0 110-1.3.65.65 0 010 1.3z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                                <span>{t("create_payment.failure_url_info")}</span>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} className="mb-3">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>{t("create_payment.merchant_commission_percentage.label")} *</Form.Label>
                              <Field
                                className="form-control"
                                placeholder={t("create_payment.merchant_commission_percentage.placeholder")}
                                name="merchant_commission_percentage"
                                type="number"
                              />
                              <ErrorMessage component={"div"} name="merchant_commission_percentage" className="errorMsg w-100" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Form.Group className="mb-0" controlId="exampleForm.ControlTextarea1">
                              <Form.Label>{t("create_payment.description.label")}</Form.Label>
                              <Field
                                as="textarea"
                                rows="4"
                                className="form-control"
                                placeholder={t("create_payment.description.placeholder")}
                                name="description"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <div className="payment-link-btn-sec mt-3">
                        <Button
                          type="submit"
                          className="efi-payment-btn"
                          disabled={paymentSave.buttonDisable || (!profile.data.upi_method && !profile.data.card_method && !profile.data.crypto_method)}
                        >
                          {paymentSave.buttonDisable ? (
                            <ButtonLoader variant="black" />
                          ) : (
                            params.paymentId ? t("create_payment.update") : t("create_payment.create")
                          )}
                        </Button>
                      </div>
                    </div>
                  </FORM>
                )}
              </Formik>
            </Tab.Container>
          ) : null}
        </div>
      </div>
      {paymentLinkCreated && (
        <PaymentLinkCreatedModal
          paymentId={params.paymentId || ""}
          paymentLinkCreated={paymentLinkCreated}
          closePaymentLinkCreatedModal={closePaymentLinkCreatedModal}
          setPaymentLinkCreated={setPaymentLinkCreated}
        />
      )}
    </>
  );
};

export default CreatePaymentLink;
